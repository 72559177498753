import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import useNavigate
import logo from '../images/logo/mms-logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header.css'; // Custom styles
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
 
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img
                src={logo} 
                alt="MADURAI MEENAKSHI SCULPTURES" 
              />
            </NavLink>
             <a href='/' className="companyName">MADURAI MEENAKSHI SCULPTURES</a>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''} justify-content-end`}>
              <ul className="navbar-nav">
                {[
                  { path: '/', label: 'Home' },
                  { path: '/about', label: 'About Us' },
                  { path: '/products', label: 'Our Products' },
                  { path: '/offers', label: 'Special Offers' },
                  { path: '/contact', label: 'Contact Us' },
                ].map((link) => (
                  <li className="nav-item" key={link.path}>
                    <NavLink
                      className="nav-link"
                      to={link.path}
                      activeClassName="active"
                      onClick={() => setShowMenu(false)}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}
               
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;