import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';
import contactusBanner from '../assets/images/banners/contactus.jpg';
import MapComponent from "./MapComponent";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "", // New field for dropdown
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    service: "", // Error for dropdown
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations([
      {
        name: "USA",
        address: "Prosper, TX-75078",
        phone: " +1 818-370-2415",
      },
      {
        name: "Madurai:",
        address: "Aavin nager,Surveyor colony,Madurai, Tamil Nadu, 625007"
      },
      {
        name: "Swamilmalai:",
        address:"Mela Veedhi,Swamimalai, Kumbakonam, 612302"
      },
    ]);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10,10}$/;

    // Validation rules
    const validationRules = {
      name: value.length > 0 ? "" : "Name is required",
      phone: phoneRegex.test(value) ? "" : "Please enter a valid phone number",
      email: emailRegex.test(value) ? "" : "Please enter a valid email address",
      service: value.length > 0 ? "" : "Please select a service", // Validation for dropdown
      message: value.length > 0 ? "" : "Message is required",
    };

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validationRules[name] || "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
      setLoading(true);
      try {
        await emailjs.send(   
        "service_3g3rqri",
        "template_amdg2te",
        formData,
        "22Rk1x7nRvWpUw37o"
        );
        setToastMessage({
          type: "success",
          text: "Your message has been sent successfully!",
        });
        setFormData({ name: "", phone: "", email: "", service: "", message: "" });
      } catch (error) {
        setToastMessage({
          type: "error",
          text: "Failed to send your message, please try again.",
        });
      } finally {
        setLoading(false);
      }
    } else {
      setToastMessage({
        type: "error",
        text: "Please correct the errors before submitting.",
      });
    }
  };

  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => setToastMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [toastMessage]);

  return (
    <div className="container-fluid py-3">
    <div className="container contactus">
      <div className="banner-image">
        <img
          className="img-fluid rounded"
          alt="Contact Us"
          src={contactusBanner}
        />
      </div>
      <div className="row my-3 ml-3">

         {/* Location Details */}
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title mb-4 text-primary">Our Locations</h2>
              {locations.map((location, index) => (
                <div key={index} className="mb-2 text-start">
                  <h5 className="fw-bold">{location.name}</h5>
                  <p>{location.address}</p>
                  {location.phone && (
                      <p>
                        <strong>Phone:</strong> {location.phone}
                      </p>
                   )}
                </div>
              ))}
              <div>
                <p className="mb-3 text-start">
                  <strong>Email:</strong>{" "}
                  <p>
                  selvaips@gmail.com
                  </p>
                  </p>
                  <div className="mb-1 text-start">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Form */}
        <div className="col-md-6">
  <div className="card shadow">
    <div className="card-body">
      <h2 className="card-title mb-4 text-primary">Contact Us</h2>
      <form onSubmit={handleSubmit}>
        {toastMessage && (
          <div
            className={`alert ${
              toastMessage.type === "success" ? "alert-success" : "alert-danger"
            } mb-3`}
            role="alert"
          >
            {toastMessage.text}
          </div>
        )}

        {/* Name and Phone Inputs in a single row */}
        <div className="row mb-3 text-start">
          {/* Name Input */}
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`form-control ${
                errors.name ? "is-invalid" : ""
              }`}
              required
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>

          {/* Phone Input */}
          <div className="col-md-6">
            <label htmlFor="phone" className="form-label">
              Phone Number
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={`form-control ${
                errors.phone ? "is-invalid" : ""
              }`}
              required
            />
            {errors.phone && (
              <div className="invalid-feedback">{errors.phone}</div>
            )}
          </div>
        </div>

        {/* Email and Service Dropdown in a single row */}
        <div className="row mb-3 text-start">
          {/* Email Input */}
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`form-control ${
                errors.email ? "is-invalid" : ""
              }`}
              required
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>

          {/* Service Dropdown */}
          <div className="col-md-6">
            <label htmlFor="service" className="form-label">
              Interested In
            </label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              className={`form-control ${
                errors.service ? "is-invalid" : ""
              }`}
              required
            >
              <option value="God Statues">God Statues</option>
              <option value="Kuthu Viakku">Kuthu Viakku</option>
              <option value="Others">Others</option>
            </select>
            {errors.service && (
              <div className="invalid-feedback">{errors.service}</div>
            )}
          </div>
        </div>

        {/* Additional Details Input in a single row */}
        <div className="mb-3 text-start">
          <label htmlFor="message" className="form-label">
            Additional Details
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className={`form-control ${
              errors.message ? "is-invalid" : ""
            }`}
            rows="4"
            placeholder="Type your message here..."
            required
          />
          {errors.message && (
            <div className="invalid-feedback">{errors.message}</div>
          )}
        </div>

        {/* Submit Button */}
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary w-50"
            disabled={loading}
          >
            {loading ? "Sending..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


       
      </div>
      <div className="row my-3 ml-3">
      <MapComponent />
      </div>
      </div>
      </div>
  );
};

export default Contact;
