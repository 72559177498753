import React from 'react';
import SocialIcons from './Socialicons';
const Footer = () => {
  return (
    <footer className="footer bg-lightblue text-white  mt-auto">
      <div className="padRL10 d-lg-block">
        <div className="row gx-0">
          <div className=" col-lg-10 text-center text-lg-start mt-2 mb-lg-0">
            <div className=" col-lg-8 d-inline-flex align-items-center ">
              <p className='ps-3'>&copy;2025 Madurai Meenakshi Sculptures - USA , all rights reserved</p>
              <p > &nbsp;| Developed by <a href='https://neinus.com' target="_blank" className="fw-bold" rel="noreferrer">Neinus Technologies</a>  </p>
            </div>

            
           
          </div>
          <SocialIcons />
        </div>
      </div>
    </footer>
  )
}
export default Footer;
