import React from 'react';
import offerBanner from '../assets/images/banners/specialoffer.jpg';
import aboutusImg from '../assets/images/banners/about.jpg';
import ReactCountryFlag from "react-country-flag";

const SpecialOffer = () => {
  return (
    
    <div className="container-fluid py-3">
      <div className="container">
      <div class="banner-image">
            <img className="img-fluid rounded" alt="About Us" src={offerBanner} />
        </div>
        <div className="container py-3">
        <h2 className="fw-bold text-primary text-uppercase text-center">Special Offers</h2> </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            {/* <div className="section-title position-relative pb-3 mb-1">
              <h1 className="mb-0">Empowering Businesses through Innovative Solutions</h1>
            </div> */}
            <p className="mb-4">
            <h5 className="mb-3">Special Offers & Discounts</h5>
              🎉 Unlock Exclusive Savings! 🎉 <br /><br />
              1. Limited-Time Offer: 15% Off<br />
              Enjoy a flat 15% discount on all purchases! Whether you’re looking for exquisite sculptures, intricate designs, or personalized creations, this is the perfect time to bring your vision to life.<br/>
              🛒 Don’t wait! This offer is available for a limited period only. Act now to secure your favorite pieces at unbeatable prices.</p>
            <p className="mb-4">
            2. Referral Reward: Get 20% Off Your Next Order<br />
              Spread the word and share the beauty! Refer friends, family, or colleagues to us, and as a thank-you, we’ll give you an additional 20% discount on your next order.<br />
              💡 Here’s how it works:<br />
              Share our products with your network.<br />
              When your referral makes a purchase, you receive your discount.<br />
              Redeem your savings on your next purchase—it’s that simple!<br />

            </p>
            <p className="mb-4">
            🌟 Why Wait? Take Advantage of These Offers Today! 🌟<br />
              Elevate your collection or find the perfect gift with these special discounts. Our handcrafted sculptures and designs ensure unmatched quality and beauty.<br />
              <p className="text-success"><strong>Call us for custom order & special Price!!</strong> </p>
            </p>
            <div className="row mb-3">
              <div className="col-md-6">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Customized Idols</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Fast Delivery</h5>
              </div>
              <div className="col-md-6">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Delivery All over USA</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Single / Bulk Orders</h5>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
            
              <div className="card-text ps-3">
              <h5 className="mb-1 text-primary">Factory located in  Madurai and Swamimalai, Tamilnadu, India </h5>
                <div className="mb-2">
                  <ReactCountryFlag countryCode="US" svg className="me-2" />
                  <strong>USA</strong>
                   <p><strong>Prosper, TX-75078</strong> <br/>
                    selvaips@gmail.com<br/>
                  <strong className='text-primary fw-bold'>+1 818-370-2415</strong>  </p>
                </div>
              </div>
            </div>
            <a href="/products" className="btn btn-success py-3 px-5 mt-3">Place an Order</a>
          </div>
          <div className="col-lg-5 col-md-12">
            <img className="img-fluid rounded drop-shadow" alt="About Us" src={aboutusImg} />
          </div>
        </div>
      </div>
    
    </div>
  
  );
}

export default SpecialOffer;
