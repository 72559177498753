import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import aboutusBanner from '../assets/images/banners/products.jpg';
import '../assets/css/Product.css';
import products from '../utils/Products.json';

const Products = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleOpenModal = (product) => {
    setCurrentProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentProduct(null);
  };

  const handleNextProduct = () => {
    const currentIndex = products.findIndex(p => p.id === currentProduct.id);
    const nextProduct = products[(currentIndex + 1) % products.length];
    setCurrentProduct(nextProduct);
  };

  const handlePreviousProduct = () => {
    const currentIndex = products.findIndex(p => p.id === currentProduct.id);
    const prevProduct = products[(currentIndex - 1 + products.length) % products.length];
    setCurrentProduct(prevProduct);
  };

  return (
    <div className="container-fluid py-3">
      <div className="container">
        <div className="banner-image">
          <img className="img-fluid rounded" alt="About Us" src={aboutusBanner} />
        </div>
        <div className="container py-3">
          <h2 className="fw-bold text-primary text-uppercase text-center">Products</h2>
        </div>
        <div className="row">
          {products.map(product => (
            <div key={product.id} className="col-md-4 mb-4">
              <div className="card" onClick={() => handleOpenModal(product)}>
                <img src={product.image} alt={product.title} className="card-img-top thumbnail-image" />
                <div className="card-body">
                  <h5 className="card-title fw-bold text-primary text-uppercase text-center">
                    {product.title} <br /> {product.code}
                  </h5>
                  <p className="card-text">{product.description}</p>
                  {product.size && <p>{product.size}</p>}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Modal for larger view of the product */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
  {currentProduct && (
    <div className="modal-content custom-modal">
      {/* Close Button */}
      <button className="btn-close" onClick={handleCloseModal}></button>
      {/* Left Prev Button */}
      <button className="arrow-btn left-arrow" onClick={handlePreviousProduct}>
  Prev
</button>

      {/* Modal Content */}
              <Modal.Body>
              <h5 className="text-center text-primary fw-bold mt-3">{currentProduct.title}</h5>
        <img
          src={currentProduct.image}
          alt={currentProduct.title}
          className="img-fluid modal-image"
        />
       <p className="text-center text-primary fw-bold">{currentProduct.code}</p>
        <p className="text-center">{currentProduct.description}</p>
      </Modal.Body>

      {/* Right "Next" Button */}
<button className="arrow-btn right-arrow btn btn-success" onClick={handleNextProduct}>
  Next
</button>
    </div>
  )}
</Modal>

      </div>
    </div>
  );
};

export default Products;
