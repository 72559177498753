import React from "react";

const MapComponent = () => {
  return (
    <div>
      <h2>Our Location</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3336.7041930147043!2d-96.79948712421971!3d33.24805217347039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c3ffd8fa677ef%3A0xa162a4daa772ad06!2sProsper%20Trail%2C%20Texas%2075078!5e0!3m2!1sen!2sus!4v1737245878548!5m2!1sen!2sus"
        width="100%"
        height="450"
        allowFullScreen=""
        loading="lazy"
        title="Google Map"
      ></iframe>
    </div>
  );
};

export default MapComponent;
