import React from 'react';
import aboutusBanner from '../assets/images/banners/mms-about.jpg';
import aboutusImg from '../assets/images/banners/aboutus.png';
import ReactCountryFlag from "react-country-flag";

const AboutUs = () => {
  return (
    
    <div className="container-fluid py-3">
      <div className="container">
      <div class="banner-image">
            <img className="img-fluid rounded" alt="About Us" src={aboutusBanner} />
        </div>
        <div className="container py-3">
        <h2 className="fw-bold text-primary text-uppercase text-center">About Us</h2> </div>
        <div className="row a">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            {/* <div className="section-title position-relative pb-3 mb-1">
              <h1 className="mb-0">Empowering Businesses through Innovative Solutions</h1>
            </div> */}
            <p className="mb-4">For nearly an century, we've dedicated ourselves to crafting magnificent Panchaloka idols of various deities. Our roots trace back to Mayiladuthurai, Tamil Nadu, where our ancestors mastered their art using traditional methods and ancient Vedic knowledge.</p>
            <p className="mb-4">With these time-honored practices, we've upheld the highest standards. Over the past 75 years, we've proudly created over 8000 idols for temples across India and international destinations like the United States, Mauritius, Malaysia, Singapore, Sri Lanka, and Thailand.</p>
            <p className="mb-4">
            Some of the notable temples, including the Vinayaka Temple in New York and Sai Baba Temple in Virginia, feature our exceptional work. Beyond idols, we specialize in crafting gold and silver chariots, nagasu, gopurams, kodi maram (sacred trees), and gold/silver steps.</p>
           
            <div className="d-flex align-items-center mb-4">
            
              <div className="card-text ps-3">
              <h5 className="mb-1 text-primary">Factory located in  Madurai and Swamimalai, Tamilnadu, India </h5>
                <div className="mb-2">
                  <ReactCountryFlag countryCode="US" svg className="me-2" />
                  <strong>USA</strong>
                   <p><strong>Prosper, TX-75078</strong> <br/>
                    selvaips@gmail.com<br/>
                  <strong className='text-primary fw-bold'>+1 818-370-2415</strong>  </p>
                </div>
              </div>
            </div>
            <a href="/products" className="btn btn-success py-3 px-5 mt-3">Place an Order</a>
          </div>
          <div className="col-lg-5 col-md-12">
            <img className="img-fluid rounded drop-shadow" alt="About Us" src={aboutusImg} />
          </div>
        </div>
      </div>
    
    </div>
  
  );
}

export default AboutUs;
