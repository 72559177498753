// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap'; // Import Container, Row, and Col from react-bootstrap
import ImageCarousel from './ImageCarousel';
import AboutUs from './About';
import Products from './Products';
import SpecialOffer from './SpecialOffer';
import Contact from './Contact';

import smImage from '../images/sm_x.jpg'; // Import the image using a relative path

const Home = () => {
 
  return (
    <div className="home">
      <Helmet>
        <title>Madurai Meenakshi Sculptures - USA</title>
        <meta
          name="description"
          content="We excel at equipping artists with the skills and knowledge needed for a diverse range of metal sculpture techniques, catering to various artistic styles and creative expressions"
        />
        <meta
          name="keywords"
          content="Madurai Meenakshi Sculptures, metal sculptures, artistic sculptures, sculpture techniques, metal art, custom sculptures, Madurai artists, creative sculptures, sculpture design, bronze sculptures, brass sculptures, traditional art, modern sculptures, handmade sculptures, sculpture workshops, artistic expressions, sculpture skills, metalwork art, sculpture styles, custom art, statue making, sculpture training, unique sculptures, sculpture expertise, artistic creations, Madurai art culture, sculptures for sale, selling sculptures in USA, Indian sculptures in USA, export sculptures to USA, buy sculptures online, USA sculpture market, custom statues USA, handmade sculptures USA, artistic metalwork USA"
        />
        <meta
          property="og:title"
          content="Madurai Meenakshi Sculptures -  All your sculptures under one roof"
        />
        <meta
          property="og:description"
          content="We excel at equipping artists with the skills and knowledge needed for a diverse range of metal sculpture techniques, catering to various artistic styles and creative expressions"
        />
        <meta property="og:image" content={smImage} />
        <meta property="og:url" content="https://mmsculptures.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="MADURAI MEENAKSHI SCULPTURES - All your sculptures under one roof" />
        <meta
          name="twitter:description"
          content="We excel at equipping artists with the skills and knowledge needed for a diverse range of metal sculpture techniques, catering to various artistic styles and creative expressions"
        />
        <meta name="twitter:image" content={smImage} />
      </Helmet>
      <Row>
          <Col>
            <ImageCarousel />
          </Col>
        </Row>
      <Container>
       
        <Row>
          <Col>
            <main className="content">
              <AboutUs />
            </main>
          </Col>
        </Row>
        <Row>
          <Col>
            <main className="content">
              <Products />
            </main>
          </Col>
        </Row>
        <Row>
          <Col>
            <main className="content">
              <SpecialOffer />
            </main>
          </Col>
        </Row>
       
        {/* <Row>
          <Col>
            <main className="content">
              <Clients clients={clientData} />
            </main>
          </Col>
        </Row> */}
        {/* Uncomment this block if you want to include the ContactUs component */}
       <Row>
          <Col>
            <main className="content">
              <Contact />
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
