import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Products from './components/Products';
import SpecialOffer from './components/SpecialOffer';
import Contact from './components/Contact';
import Footer from './components/Footer';
import WhatsAppIcon from './components/WhatsAppIcon'; 
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <div >
       
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/products" element={<Products />} />
              <Route path="/offers" element={<SpecialOffer />} />
              <Route path="/contact" element={<Contact />} />   
            </Routes>
          </div>
          <WhatsAppIcon phoneNumber="18183702415" /> {/* MMS WhatsApp number */} 
      <Footer />
    </Router>
  );
}

export default App;
